.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.blog-pg.single .post .title-head {
  text-align: center;
  margin-bottom: 50px;
  color: #fff;
}
.blog-pg.single .post .title-head h2 {
  margin-bottom: 10px;
}
.blog-pg.single .post .title-head a {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 10px;
}
svg.CircularProgressbar {
  width: 110px;
  height: 110px;
}
a,
.nav-link,
.works.filter-img .filtering .filter span {
  cursor: pointer;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.testimonials.grid .testim .slick-track .slick-active > div {
  position: relative;
  margin: 0 20px;
}
.testimonials.grid .testim .slick-track .item {
  margin: 0;
}
#before-after-slider {
  width: 100%;
  position: relative;
  overflow: hidden;
}
#before-after-slider img {
  all: unset;
  width: 100%;
}
#after-image {
  display: block;
}
.path a {
  text-transform: capitalize;
}
#before-image {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 2;
}
.contact .form input,
.contact .form textarea {
  color: #fff;
}
.twentytwenty-handle:before,
.twentytwenty-handle:after {
  content: " ";
  display: block;
  background: white;
  position: absolute;
  z-index: 30;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  width: 3px;
  height: 9999px;
  left: 50%;
}
.twentytwenty-left-arrow,
.twentytwenty-right-arrow {
  width: 0;
  height: 0;
  border: 6px inset transparent;
  position: absolute;
  top: 50%;
  margin-top: -6px;
}
.twentytwenty-handle:after {
  bottom: 100%;
}
.twentytwenty-handle {
  height: 38px;
  width: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border: 3px solid white;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  border-radius: 1000px;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  z-index: 40;
  cursor: pointer;
}
.twentytwenty-left-arrow {
  border-right: 6px solid white;
  left: 50%;
  margin-left: -17px;
}
.twentytwenty-right-arrow {
  border-left: 6px solid white;
  right: 50%;
  margin-right: -17px;
}
#before-after-slider .overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
  opacity: 0;
  transition: opacity 0.3s linear;
}
#before-after-slider:hover .overlay {
  opacity: 1;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

[data-overlay-dark]:before {
  background: #02050b;
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.twentytwenty-before-label,
.twentytwenty-after-label {
  opacity: 0;
}
.twentytwenty-before-label:before,
.twentytwenty-after-label:before {
  content: attr(data-content);
}
.justified-gallery img {
  height: 100%;
  object-fit: cover;
  min-height: 335px;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

.twentytwenty-before-label:before {
  left: 10px;
}
.twentytwenty-after-label:before {
  right: 10px;
}
a.col-lg-4.col-xl-3.col-md-6 {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
.twentytwenty-before-label,
.twentytwenty-after-label {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s linear;
}
a.col-lg-4.col-xl-3.col-md-12 {
  margin-bottom: 10px;
}
.twentytwenty-before-label:before,
.twentytwenty-after-label:before {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  line-height: 38px;
  padding: 0 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  color: white;
  font-size: 13px;
  letter-spacing: 0.1em;
  z-index: 100;
  top: 50%;
  margin-top: -19px;
}
.justified-gallery {
  margin: 10px 0;
}
.pages-header .cont h1 {
  text-transform: capitalize;
}
.slick-dots li button {
  display: block !important;
  opacity: 0;
  width: 0;
  height: 0;
}
.testimonials2 .slick-list {
  margin: 0 !important;
}
.slid-half .nofull:after {
  z-index: 1;
}
.team {
  overflow: hidden;
}
.swiper-pointer-events.swiper-vertical,
.cta__slider-arrows,
.slid-half .swiper-pagination-fraction.top {
  z-index: 55;
}

.testimonials2 .slick-slide > div {
  padding: 0 10px !important;
}
@media (max-width: 991px) {
  .navbar .navbar-collapse {
    display: block;
    opacity: 0;
    padding: 0;
    height: 0;
    transition: all 0.3s linear;
  }
  .navbar .navbar-collapse.show-with-trans {
    opacity: 1;
    padding: 10px 15px;
    height: auto;
  }
}
.hideX #preloader {
  display: none !important;
  z-index: -1111111;
  height: 0;
  width: 0;
  overflow: hidden;
}
.hideX .pace {
  display: none !important;
}
.pace-done #preloader:after {
  bottom: 0;
}
.pace-done #preloader {
  visibility: hidden;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.pace-done #preloader:after,
.pace-done #preloader:before {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
body.hideX #preloader {
  display: none !important;
  z-index: -1111111;
  height: 0;
  width: 0;
  overflow: hidden;
}
body.hideX .pace {
  display: none !important;
  background-color: transparent;
}
.map-box iframe,
.contact-map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pages-header {
  height: 65vh;
  margin-top: 100px;
  width: 90%;
  margin-left: 5%;
}
.pages-header .cont h1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.pages-header .cont .path {
  color: #fff;
  font-size: 17px;
}
.pages-header .cont .path .active {
  color: #c5a47e;
}
.pages-header .cont .path span {
  margin: 0 20px;
  font-size: 10px;
}

.blog-pg.single .post .title-head {
  text-align: center;
  margin-bottom: 50px;
}
.blog-pg.single .post .title-head h2 {
  margin-bottom: 10px;
}
.blog-pg.single .post .title-head a {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 10px;
}

.blog-pg.single .post .main-img .simpleParallax {
  height: 70vh;
  max-height: 600px;
}

.blog-pg.single .post .main-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.blog-pg.single .post .content p {
  font-size: 16px;
  font-family: inherit;
  margin: 30px 0;
}
.next-project .content p {
  font-size: 18px;
  color: #555;
}

.call-action .content p {
  font-size: 17px;
  font-weight: 300;
}

.next-project .content p {
  font-size: 18px;
  color: #555;
}

.blog-pg.single .post .content p {
  color: #555;
  font-size: 16px;
  font-family: inherit;
  margin: 30px 0;
}

.call-action .content p {
  font-size: 17px;
  font-weight: 300;
}

.blog-pg.single .post .content ul li {
  margin: 10px 0;
}
.blog-pg.single .post .content ul li span {
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
}

.blog-pg.single .post .content .quotes {
  background: #212121;
  padding: 30px;
  margin: 30px 0;
}
.blog-pg.single .post .content .quotes p {
  font-size: 18px;
  font-style: italic;
}

.blog-pg.single .post .content .spacial p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.blog-pg.single .post .content .share-info {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-pg.single .post .content .share-info .social a {
  font-size: 13px;
  margin-right: 20px;
}
.blog-pg.single .post .content .share-info .tags {
  margin-left: auto;
}
.blog-pg.single .post .content .share-info .tags a {
  font-size: 12px;
  margin: 0 10px;
}

.blog-pg.single .post .author {
  padding: 30px;
  margin-top: 40px;
  background: #212121;
}
.blog-pg.single .post .author .author-img {
  display: table-cell;
  vertical-align: middle;
  width: 140px;
}
.blog-pg.single .post .author .info {
  display: table-cell;
  padding-left: 40px;
}
.blog-pg.single .post .author .info h6 {
  margin-bottom: 10px;
  font-size: 15px;
}
.blog-pg.single .post .author .info h6 span {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 10px;
}
.blog-pg.single .post .author .info p {
  padding: 0;
  margin: 0;
}
.blog-pg.single .post .author .info .social {
  margin-top: 15px;
  font-size: 13px;
}

.post .author .info .social a {
  margin-right: 15px;
}

.blog-pg.single .pagination {
  margin-top: 80px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-pg.single .pagination span {
  width: 100%;
  padding: 20px;
  border-right: 1px solid #333;
  border-left: 1px solid #333;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
}
.blog-pg.single .pagination .icon {
  border: 0;
  width: 70px;
}

.blog-pg.single .comments-area {
  margin-top: 80px;
}
.blog-pg.single .comments-area .item {
  padding: 30px 0;
  margin: 30px 0;
  border-bottom: 1px solid #333;
}
.blog-pg.single .comments-area .item.relped {
  margin-left: 100px;
}
.blog-pg.single .comments-area .item .comment-img {
  display: table-cell;
  width: 80px;
}
.blog-pg.single .comments-area .item .info {
  display: table-cell;
  vertical-align: middle;
  padding-left: 40px;
  position: relative;
}
.blog-pg.single .comments-area .item .info h6 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
.blog-pg.single .comments-area .item .info h6 span {
  display: block;
  color: #c5a47e;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}
.blog-pg.single .comments-area .item .info p {
  line-height: 1.5;
  margin-top: 15px;
}
.blog-pg.single .comments-area .item .info .replay {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
}
.blog-pg.single .comments-area .item .info .replay i {
  margin-left: 5px;
}

.blog-pg.single .comment-form {
  margin-top: 80px;
}
.blog-pg.single .comment-form form {
  margin-top: 50px;
}
.blog-pg.single .comment-form form input,
.blog-pg.single .comment-form form textarea {
  color: #fff;
  width: 100%;
  padding: 17px;
  background: #212121;
  border: 0;
}
.blog-pg.single .comment-form form textarea {
  height: 140px;
}
.blog-pg.single .comment-form form button {
  background: #c5a47e;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .pages-header .cont h1 {
    font-size: 40px;
  }
  .blog-pg .posts .item .content {
    position: relative;
  }
  .blog-pg .posts .item .content .col-10,
  .blog-pg .posts .item .content .col-2 {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .blog-pg .posts .item .content .col-2 {
    position: absolute;
    right: 0px;
    top: -90px;
    z-index: 3;
  }
  .blog-pg .posts .item .content .date a {
    padding: 10px;
    background: #fff;
    margin: 0;
  }
  .blog-pg .posts .item .content .date .num {
    font-size: 25px;
    margin: 0;
  }
  .blog-pg.single .comments-area .item.relped {
    margin-left: 0px;
  }
  .blog-pg.single .comments-area .item .info h6 span {
    display: block;
    margin: 10px 0 0 0;
  }
}

@media screen and (max-width: 480px) {
  .fz-40 {
    font-size: 25px !important;
  }
  .section-head h3 {
    font-size: 28px;
  }
  .main-header h3 {
    font-size: 35px;
  }
  body.index2 .section-head h3 {
    font-size: 45px;
  }
  .cta__slider-item .caption .thin {
    letter-spacing: 2px;
  }
  .cta__slider-item .caption h1 {
    font-size: 28px;
  }
  .header .caption:after,
  .header .caption .bord {
    display: none;
  }
  .header .caption {
    padding: 0;
  }
  .pages-header .cont .path {
    font-size: 15px;
  }
  .pages-header .cont .path span {
    margin: 0 10px;
  }
  .intro .cont h2 {
    font-size: 35px;
  }
  .about .img .feat {
    display: block;
  }
  .about .img .feat .item {
    margin-bottom: 30px;
  }
  .about .img .feat .item:last-of-type {
    margin-bottom: 0;
  }
  .video .container .stauts .item {
    min-width: auto;
    padding: 20px 30px;
  }
  .about-us .img {
    padding: 0;
  }
  .about-us .img .stauts .item {
    padding: 20px 30px;
  }
  .portfolio .swiper-slide,
  .portfolio .swiper-slide:nth-child(3n),
  .portfolio .swiper-slide:nth-child(2n),
  .portfolio .swiper-container {
    width: 100%;
  }

  .portfolio .controls {
    right: 10px;
  }
  .blog-pg.single .post .title-head a {
    letter-spacing: 0;
    margin: 0 7px;
  }
  .blog-pg .posts .item .img .info {
    padding: 10px;
  }
  .blog-pg .posts .item .img .info .date .num {
    font-size: 20px;
  }
  .blog-pg.single .post .content .share-info {
    display: block;
  }
  .blog-pg.single .post .content .share-info .tags,
  .blog-pg.single .post .content .share-info .social {
    display: block;
    width: 100%;
    text-align: center;
  }
  .blog-pg.single .post .content .share-info .tags {
    margin-top: 20px;
  }
  .blog-pg.single .post .author {
    padding: 30px 15px;
  }
  .blog-pg.single .post .author .author-img {
    display: block;
    width: 120px;
    margin: 0 auto 30px;
  }
  .blog-pg.single .post .author .info {
    display: block;
    padding: 0;
    text-align: center;
  }
  .blog-pg.single .comments-area .item .comment-img {
    display: block;
    margin: 0 auto 30px;
    width: 60px;
  }
  .blog-pg.single .comments-area .item .info {
    display: block;
    padding: 0;
    text-align: center;
  }
  .blog-pg.single .comments-area .item.relped {
    margin-left: 0px;
  }
  .blog-pg.single .comments-area .item .info h6 {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .blog-pg.single .comments-area .item .info .replay {
    top: -25px;
  }
  .clients-brand .img {
    width: 60px;
  }
}
