body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bootstrap-container {
  width: 100%;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);
  margin-left: auto;
  margin-right: auto;
}

.blog-pg .posts .item .content {
  padding-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-pg .posts .item .content .date {
  margin-right: 40px;
}
.blog-pg .posts .item .content .date span {
  font-size: 16px;
}
.blog-pg .posts .item .content .date .num {
  font-size: 60px;
}
.blog-pg .posts .item .content .tags {
  margin-bottom: 10px;
}
.blog-pg .posts .item .content .tags a {
  font-size: 12px;
  font-weight: 500;
  color: #c5a47e;
  padding-right: 20px;
  position: relative;
}
.blog-pg .posts .item .content .tags a:after {
  content: "";
  width: 8px;
  height: 2px;
  background: #999;
  position: absolute;
  right: 4px;
  top: 50%;
}
.blog-pg .posts .item .content .tags a:last-of-type {
  padding-right: 0;
}
.blog-pg .posts .item .content .tags a:last-of-type:after {
  display: none;
}
.blog-pg .posts .item .content h4 {
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 5px;
}
.blog-pg .posts .item .content .more {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 60px;
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.blog-pg .posts .item .content .more:after {
  content: "";
  width: 50px;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.blog-pg .posts .item .content .more:hover {
  padding-left: 40px;
}
.blog-pg .posts .item .content .more:hover:after {
  width: 30px;
}

.blog-pg .posts .pagination {
  margin: 80px auto 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}
.blog-pg .posts .pagination span {
  text-align: center;
  margin: 0 2px;
}
.blog-pg .posts .pagination span a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #eee;
  font-size: 18px;
}
.blog-pg .posts .pagination span.active a {
  border-color: #c5a47e;
  color: #c5a47e;
}

@media (min-width: 576px) {
  .bootstrap-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .bootstrap-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .bootstrap-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .bootstrap-container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .bootstrap-container {
    max-width: 1320px;
  }
}